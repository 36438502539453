import React, { useState,useEffect } from 'react'
import LandingCarousel from "../../components/LandingCarousel";
import Profile from '../../shared/Profile';
import { Helmet } from "react-helmet"
import AOS from 'aos';
import { Link } from 'react-router-dom';

export default function AboutPage() {

    const [team, setTeam] = useState([
        { name: "فاطمة عناني", position: "شريك مؤسس والمدير العام", description: "فاطمة تتمتع بخبرة عملية لأكثر من 7 سنوات في جهات عالمية منها جامعتي هارفارد وشيكاغو والغرفة الإسلامية للتجارة والصناعة والزراعة، كما عملت كمستشارة وباحثة بالتعاون مع وزارة الموارد البشرية والتنمية الإجتماعية. حاصلة على درجة الماجستير في السياسة العامة من جامعة جورجتاون وبكالوريوس في نظم المعلومات الإدارية من جامعة دار الحكمة بجدة. تطمح فاطمة لمساعدة من حولها في التقدم والتطور، وتدير فريقها بشغف وإيمان وروح مليئة بالحيوية والتفاؤل.", imageUrl: "user1.png", linkedInAccount: 'https://www.linkedin.com/in/fatimahanani/' },
                { name: "العنود الزهراني", position: "شريك مؤسس ", description: "خبرة العنود المهنية تمتد لأكثر من 7 سنوات في بنوك و شركات الاستشارات الإدارية وشركات ناشئة، كما عملت مع شركات توظيف عالمية في قيادة عملية توظيف أكثر من 100 شخص. وعملت في تطوير الاستراتيجيات والخطط لأكثر من 16 جهة خاصة وحكومية. حازت العنود على جائزة الأمير محمد بن فهد لإعداد القيادات الشابة في عام 2012. حاصلة على درجة البكالوريوس في نظم المعلومات الإدارية من جامعة دار الحكمة بجدة. تتميز العنود بقدرتها على التعامل مع التحديات بتفكيرها التحليلي وسعيها الدائم للارتقاء والنمو        .", imageUrl: "user2.png", linkedInAccount: 'http://www.linkedin.com/in/alanoudalzahrani'},

        { name: "دينا الجاويش", position: " خبيرة LinkedIn", description: "تتمتع دينا بخبرة طويلة في العمل لدى الشركات العالمية في مجالات الاتصالات والتسويق مع التخصص في كتابة الإعلانات وإنشاء المحتوى، كما عملت كمستشارة في التسويق والاتصال وإعادة الهيكلة التنظيمية. حاصلة على درجة الماجستير في إدارة الأعمال وشهادة البكالوريوس في تكنولوجيا المعلومات. تتميز دينا بعدد الدورات التي تأخذها واستغلالها لكل الفرص التنموية، و هي شغوفة بالتعليم والتدريب ومساعدة الآخرين.", imageUrl: "user3.png", linkedInAccount: 'https://www.linkedin.com/in/delgawish/'},
        { name: "مجدي قاري", position: "مستشار", description: "وظيفة مجدي الحالية هي مدير عام الموارد البشرية في شركة هلا. عمل سابقا كمستشار توظيف في شركة EY حيث رفع معدل السعودة في الشركة بنسبة 30٪، وقام بتوظيف أكثر من 100 محترف سعودي. خبرته تشمل مختلف وظائف الموارد البشرية، وشارك في العديد من برامج الاستعداد والوعي الوظيفي، مما ساهم إلى حد كبير في رفع تصنيف الشركة. كما عمل كمسؤول توظيف في الخطوط الجوية السعودية لمدة 3 سنوات ونجح في توظيف أكثر من 150 محترفًا.", imageUrl: "user4.png", linkedInAccount: 'https://www.linkedin.com/in/majdigari' },

    ])

    useEffect(() => {
        AOS.init({
          duration: 1500
        })
      })

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>من نحن | كامن</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <AboutSection align="text-right" title="نحن هنا لمساعدتك على تحقيق طموحاتك المهنية" 
            subtitle="
            أسسنا كامن في 2019 لأننا نريد مساعدتك في الحصول على مهنة تحاكي اهتماماتك وشغفك وتتناسب مع قدراتك ومهاراتك الكامنة. فإن كانت درايتك المعرفية بفن التسويق لهويتك المهنية قليلة، أو جدولك مزدحم بأعمال أخرى، أو كان تواضعك يمنعك من التعبير عن نفسك بشكل جيّد، مهمتنا هي مساعدتك على التألق والتميز عمن حولك.
            " 
            background="building-background" />

            <div className="container">
                <div class="row">
                    <div class="col-12">
                        <h3 className="text-white mb-3">الفريق الإداري</h3>

                    </div>
                    <div className="col-12">

                        {team.map((member) => {
                                return <Profile RTL={true} name={member.name} imageUrl={member.imageUrl} position={member.position} description={member.description} linkedInAccount={member.linkedInAccount} />
                        }
                        )
                        }
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container px-4" >
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="row justify-content-between align-items-center py-3" style={{ background: "linear-gradient(90deg, #C7AB86 0%, #F6DAA5 42.19%, #FFE4B2 52.55%, #F6DAA5 61.3%, #C7AB86 93.67%, #F6DAA5 100%)", boxShadow: "0 2px 100px 0 rgba(0,0,0,0.15)" }}>
                            <div className="col-md-7">
                            <h2 className="text-dark">إنضم إلينا </h2>
                            <h6 className="text-dark">لتصبح جزءاً فعالاً في تغيير حياة عملائنا المهنية للأفضل </h6>
                        </div>
                        <div className="col-md-3">
                           <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfpNuZwyduEuFPnL_edn_3WwJyh9TLPiNgoVCTKsx1JSezVWA/viewform"> <button className="btn btn-lg btn-light btn-block d-flex justify-content-between align-items-center py-3"><span>إنضم للفريق </span><img src="images/button-arrow-left.svg" width="20" /></button></a>
                        </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}


function AboutSection(props){
    return(
        <div className={"jumbotron jumbotron-fluid landing-jumbotron mb-0 " + props.background ? props.background : 'bg-primary'}>
            <div className="container py-5">
                <div className="row mt-5">
                    <div className="col-md-12">
                <div className={"d-flex flex-column align-items-center mt-5" +props.align ? props.align : 'justify-content-center'}>
                <h1 className={"display-5 pt-5 mt-5 font-weight-bold text-white mb-5 " + props.align ? "text-right text-white mt-5 pt-5": 'text-center'} data-aos="fade-up">{props.title}</h1>
                <p className={"text-center w-50 mb-5 " + props.align ? "text-right mt-3 text-white": 'text-center'} style={{fontSize:'20px',lineHeight:'2'}} data-aos="fade-up" data-aos-delay="300">{props.subtitle}</p>
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}
