import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function SuccessPage() {
    const { t, i18n } = useTranslation();

    return (
        <div className="my-5 py-5">
        <div className="container text-center">
            <h1 className="text-center font-weight-bold mb-4">تم إستلام طلبك بنجاح</h1>
            <p className="lead text-center w-50 mx-auto text-light mb-5">سيتم التواصل معك قريبًا</p>
            <Link to="/"><button className="btn btn-primary btn-lg px-5">العودة للصفحة الرئيسية</button></Link>
        </div>
        </div>
    )
}
