import React,{useEffect} from 'react'
import AOS from 'aos';

export default function Profile(props) {
    useEffect(() => {
        AOS.init({
          duration: 1500
        })
      })
    return (
        <div className=" mb-5">
        <div className="media align-items-sm-stretch flex-column flex-md-row  overflow-hidden" style={{ background: "rgba(216,216,216,.1" }}>
            {props.RTL &&
                <img src={"images/team/" + props.imageUrl} className="align-self-streach" style={{ width: 350, maxHeight: 350 }} alt="..." />
            }
            <div className="media-body d-flex flex-column justify-content-between pt-4  overflow-hidden">
                <div className=" pr-3">
                    <h3 className="mt-0 font-weight-bold text-primary" data-aos={props.RTL ? "fade-left" : "fade-right"}>{props.name}</h3>
                    <h6 className="font-weight-bold text-primary mb-3" data-aos={props.RTL ? "fade-left" : "fade-right"} data-aos-delay="300">{props.position}</h6>
                    <p data-aos={props.RTL ? "fade-left" : "fade-right"} data-aos-delay="600">{props.description}</p>
                </div>
                <div className="my-3 mx-4" data-aos="fade-up" data-aos-delay="700">
                    {props.linkedInAccount && (
                    <a target="_blank" href={props.linkedInAccount}><i className="fab fa-2x fa-linkedin text-primary"></i></a>
                    )}
                    {/* <a><i className="fab fa-2x fa-instagram text-primary mx-4"></i></a>
                    <a><i className="fab fa-2x fa-twitter text-primary"></i></a> */}
                </div>
            </div>
        </div>    
        {!props.RTL &&
        <div style={{height:'5px',width:'100%',background:'linear-gradient(270deg, rgba(199,171,134,1) 0%, rgba(255,255,255,0) 100%)'}}></div>
    }     {props.RTL &&
        <div style={{height:'5px',width:'100%',background:'linear-gradient(90deg, rgba(199,171,134,1) 0%, rgba(255,255,255,0) 100%)'}}></div>
    }           

        </div>

    )
}
