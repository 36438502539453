import React, {useEffect} from "react";
import Query from "../Query";
import { Link, useLocation } from "react-router-dom";
import styles from './index.module.scss';


import CATEGORIES_QUERY from "../../queries/category/categories";

const Nav = () => {
  const location = useLocation();

  return (
    <div>
      <Query query={CATEGORIES_QUERY} id={null}>
        {({ data: { categories } }) => {
          return (
            <div className="nav overflow-auto">
              <div className={location.pathname === '/' ? 
              "collapse position-absolute w-100 h-100 overflow-auto" 
            : "collapse position-fixed w-100 h-100 overflow-auto"} 
              id="navbarToggleExternalContent">
                <div className="p-4 h-100">
                  <div className="d-flex align-items-start container">
                    
                    <ul className="text-white text-center list-inline mx-auto justify-content-center custom_margin ml-auto">
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <Link className="text-white list-inline-item custom_margin custom_li li-text" to="/">الرئيسية</Link>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <Link className="text-white list-inline-item custom_margin custom_li li-text" to="/about-us">من نحن</Link>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <Link className="text-white list-inline-item custom_margin custom_li li-text" to="/services">خدماتنا</Link>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <Link className="text-white list-inline-item custom_margin custom_li li-text" to="/success-stories">قصص عملائنا</Link>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <a className="text-white list-inline-item custom_margin custom_li li-text" href="https://docs.google.com/forms/d/e/1FAIpQLSfpNuZwyduEuFPnL_edn_3WwJyh9TLPiNgoVCTKsx1JSezVWA/viewform" target="_blank">انضم إلينا</a>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <Link className="text-white list-inline-item custom_margin custom_li li-text" to="/faqs">أسئلة متكررة</Link>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <div className="text-white list-inline-item custom_margin custom_li li-text">المدونة
                        <span className={styles.blogSoon}> قريبا </span></div>
                      </li>
                      <li className="text-center" data-toggle="collapse" data-target="#navbarToggleExternalContent">
                        <a className="text-white list-inline-item custom_margin custom_li li-text" href="mailto:Services@kaminsa.com">تواصل معنا</a>
                      </li>
                    </ul>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="navbar-toggler-icon"></i>
                      <span className="my-1 mx-2 close close-nav"><i className="fal fa-times"></i></span>
                    </button>
                  </div>
                </div>
              </div>
              <nav className={location.pathname === '/' ?
                ["navbar position-absolute w-100 ml-auto", styles.navContainer].join(' ')
                : ["navbar position-fixed w-100 ml-auto", styles.navContainer, styles.navColor].join(' ')} >
                <div className="position-relative w-100">
                  <div className="d-flex justify-content-between align-items-start">
                    <div className="d-flex  mr-n3 bd-highlight mt-n5 mb-5">
                      <Link to="/" className="navbar-brand py-5 px-3 bd-highlight overflow-hidden mr-0 mt-3">
                        <img src="/images/logo.svg" style={{ width: "150px" }} alt="Logo" /></Link>
                    </div>
                    <button className={[styles.headerBurgerIcon, "navbar-toggler collapsed py-2"].join(' ')} type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="navbar-toggler-icon fa fa-bars fa-2x"></i>
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          );
        }}
      </Query>
    </div>
  );
};



export default Nav;
