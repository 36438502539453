import React from "react";

import { Switch, Route } from "react-router-dom";

import Nav from "../../components/Nav";
import HomePage from "../HomePage";
import Category from "../Category";
import Footer from '../../components/Footer'
import FAQ from '../FAQ'
import AboutPage from '../AboutPage'
import ServicesPage from '../ServicesPage'
import StoriesPage from '../StoriesPage'
import ContactPage from "../ContactPage";
import ArticlesPage from "../Articles";
import ArticlePage from "../Article";
import Privacy from "../Privacy";
import SuccessPage from "../SuccessMessage";

function App() {
  return (
    <div className="App overflow-hidden">
      <Nav />
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/join-us/"  component={HomePage} exact />
        <div className="shadow-background">
          <Route path="/contact-us/" component={ContactPage} exact />
          <Route path="/about-us/" component={AboutPage} exact />
          <Route path="/services/" component={ServicesPage} exact />
          <Route path="/success-stories/" component={StoriesPage} exact />

          <Route path="/category/:id" component={Category} exact />
          <Route path="/faqs/" component={FAQ} exact />
          <Route path="/privacy-policy/" component={Privacy} exact />
          .<Route path="/success" component={SuccessPage} exact />
        </div>
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
