import React, { useEffect } from 'react'
import Feature from "../../shared/Feature";
import Services from './Services';
import { Helmet } from "react-helmet";
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

export default function ServicesPage() {
    useEffect(() => {
        AOS.init({
            duration: 1500
        })
    })

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>خدماتنا</title>

            </Helmet>
            <div style={{ height: '20vh' }}></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12" data-aos="fade-up">
                        <h1 className="font-weight-bold text-white mb-4">خدماتنا التي نقدمها لك</h1>
                    </div>
                    <div className="col-md-6 mb-5 pb-2" data-aos="fade-left">
                        <ScrollLink to="individuals" spy={true} smooth={true} duration={1500} offset={-130}  className="w-100">
                            <div className="d-flex align-items-center" style={{ borderBottom: '4px solid rgba(255,255,255,0.05)' }}>
                                <div className="ml-4 d-flex align-items-center justify-content-center padding-animation" style={{ width: '70px', height: '70px', borderRadius: '4px', background: 'rgba(255,255,255,0.5)' }}>
                                    <i className="fal fa-3x fa-angle-down text-white scale"></i>
                                </div>
                                <div>
                                    <h3 className="mt-3 font-weight-bold text-white">الأفراد</h3>
                                    <p className="text-white">رحلتنا المهنية الفريدة</p>
                                </div>

                            </div>
                        </ScrollLink>
                    </div>
                    <div className="col-md-6 mb-5 pb-2" data-aos="fade-right">
                        <ScrollLink to="companies" spy={true} smooth={true} duration={1500} offset={-20} className="w-100">
                            <div className="d-flex align-items-center" style={{ borderBottom: '4px solid rgba(255,255,255,0.05)' }}>
                                <div className="ml-4 d-flex align-items-center justify-content-center padding-animation" style={{ width: '70px', height: '70px', borderRadius: '4px', background: 'rgba(199,171,134,1)' }}>
                                    <i className="fal fa-3x fa-angle-down text-white "></i>
                                </div>
                                <div>
                                    <h3 className="mt-3 font-weight-bold text-white">الشركات</h3>
                                    <p className="text-white">خدماتنا الموجهة للشركات</p>
                                </div>

                            </div>
                        </ScrollLink>

                    </div>
                    <div className="col-md-12 mb-5" data-aos="fade-up" data-aos-delay="500" name="individuals">
                        <h3 className="text-white font-weight-bold">رحلتنا لرواية قصتك المهنية</h3>
                        <p className="lead">هي رحلة غايتها تطوير سيرتك الذاتية بطريقة تبرز مهاراتك المهنية وتسلط الضوء على مميزاتك الاستثنائية؛
وهناك أربعة أنواع:</p>
                    </div>

                </div>
                <div className="row mb-5" data-aos="fade-up" data-aos-delay="500">


                    <Services title="رحلة المبتدئين" description="أقل من 5 سنوات خبرة" image="office" price="1000 ريال سعودي" data-aos="fade-up" data-aos-delay="500" />
                    <Services title="رحلة المحترفين" description="من 5 إلى
10 سنوات خبرة" image="office2" price="1500 ريال سعودي" data-aos="fade-up" data-aos-delay="700" />
                    <Services title="رحلة المتقدمين" description="من 10 إلى 
15 سنة خبرة" image="office3" price="2000 ريال سعودي" data-aos="fade-up" data-aos-delay="900" />
                    <Services title="رحلة التنفيذيين" description="أكثر من 15 سنة خبرة" image="office4" price="2500 ريال سعودي" data-aos="fade-up" data-aos-delay="300" />
                </div>


                <div className="row mb-5 px-5 pt-4 justify-content-between pb-1" style={{ background: "rgba(216,216,216,.1" }} data-aos="fade-up" data-aos-delay="400">
                    <h2 className="col-md-12 text-white mb-4" data-aos="fade-up" data-aos-delay="500">والرحلة تشمل الخدمات التالية</h2>
                    <div className="row" data-aos="fade-up" data-aos-delay="700">


                        <Feature description="مكالمة استشارية من خبراءنا المهنيين لمعرفة قصتك وأهدافك وطموحاتك" image="phone" />
                        <Feature description='10 قوالب مختلفة لتصميم السيرة الذاتية لتختار منها' image="cvs" />
                        <Feature description='البحث في الشركات ذات الأولوية لك، ومواءمة سيرتك الذاتية مع متطلباتها' image="portfolio" />
                    </div>
                    <div className="col-12 mb-5 mr-2 px-4 py-5" style={{ background: "rgba(216,216,216,.05" }} data-aos="fade-up" data-aos-delay="700">
                        <div className="row">
                            <h2 className="col-md-12 text-white">الخدمات الإضافية<img className="mb-4" alt="plus" src="/images/plus.svg" width="20" /></h2>
                            <p className="col-md-12">إضافة إلى رحلتك، يمكنك الحصول على خدمات استثنائية بسعر موحد وقدره 600 ريال سعودي لكل منها</p>
                            <Feature title="تطوير Linkedin" image="linkedin-3" description="إعادة صياغة محتوى الصفحة وإضافة المعلومات المحبذة لزيادة نسبة فرصك الوظيفية وعلاقاتك العملية" />
                            <Feature title=' كتابة Cover Letter ' image="coverletter" description="تمنحك هذه الرسالة التعريفية فرصة لتوضيح رغبتك بالانضمام لشركة معينة مما سيزيد من فرصة إعجابهم بك" />
                            <Feature title='كتابة نبذة شخصية' image="resume" description="هي نبذة شخصية عنك باللغتين العربية والإنجليزية لوضعها في الموقع الالكتروني أو الملف التعريفي للشركة أو عند استضافتك كمتحدث" />
                            <Feature title='ترجمة السيرة الذاتية' image="translation" description="رحلتنا الأساسية تكون بكتابة سيرتك الذاتية بلغة واحدة، وهذه الخدمة توفر لك الترجمة العربية أو الإنجليزية لتسهل عليك مشاركتها مع مختلف الجهات" />
                        </div>

                    </div>
                    <div className="col-md-4 mx-auto mb-n3" data-aos="fade-in" data-aos-delay="1100">
                        <p className="text-center">ملاحظة: بدون الرحلة الأساسية، لا يمكنك الاستفادة من الخدمات الثانوية </p>
                        <a className="text-white" href="https://goo.gl/forms/7A8cypPYXqMdJZ8y2" target="_blank">
                            <button className="btn btn-light btn-block btn-lg mx-auto text-center mb-2">اطلب الخدمة الآن</button>
                        </a>
                    </div>
                </div>
                <div name="companies" className="py-3"></div>
                <div className="row my-5" style={{ border: '2px solid #5D646B' }} data-aos="fade-up" data-aos-delay="300">
                    <div className="col-md-12 px-5">
                        <h1 className="text-white font-weight-bold mt-5" data-aos="fade-up" data-aos-delay="500">خدمات الشركات</h1>
                        <p data-aos="fade-up" data-aos-delay="500">نقدم للشركات والمؤسسات 3 خدمات نسعى من خلالها مساعدتهم على تحقيق أهدافهم الاستراتيجية: </p>
                        <div className="row" data-aos="fade-up" data-aos-delay="700">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card border-0">
                                    <div className="card-img-top custom-card-img-top">
                                        <img src="/images/improvement.svg" style={{ width: 90, height: 100 }} className="mr-3" alt="..." />
                                    </div>

                                    <div className="card-body px-0 mt-3">
                                        <h4 className="card-title font-weight-bold text-white mb-4">خدمة تألَّق</h4>
                                        <p className="card-subtitle text-white">كتابة النبذة الشخصية والملف التعريفي (Bio and Profile) لمنسوبي الشركة</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card border-0">
                                    <div className="card-img-top custom-card-img-top">
                                        <img src="/images/job-interview.svg" style={{ width: 90, height: 100 }} className="mr-3" alt="..." />
                                    </div>
                                    <div className="card-body px-0 mt-3">
                                        <h4 className="card-title font-weight-bold text-white mb-4">خدمة درِّب</h4>
                                        <p className="card-subtitle text-white">تطوير وتقديم برامج تدريبية تساعد الأفراد على إبراز مهاراتهم وخبراتهم من خلال السيرة الذاتية وغيرها</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="card border-0">
                                    <div className="card-img-top custom-card-img-top pr-2">
                                        <img src="/images/cooperation.svg" style={{ width: 100, height: 100 }} className="mr-3" alt="..." />
                                    </div>
                                    <div className="card-body px-0 mt-3">
                                        <h4 className="card-title font-weight-bold text-white mb-4">خدمة شكرًا</h4>
                                        <p className="card-subtitle text-white">هدية عملية من أي من خدماتنا يمكن تقديمها في نهاية خدمة الموظفين المسرّحين لمساعدتهم على إكمال مسيرتهم المهنية</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-4" data-aos="fade-up" data-aos-delay="400">
                                <h3 className="text-white font-weight-bold" data-aos="fade-up" data-aos-delay="600">برنامجنا مع مؤسسة مسك الخيرية</h3>
                                <p data-aos="fade-up" data-aos-delay="800">أحد مشاريعنا -  برنامج مسك طريق المستقبل للإعداد الوظيفي - الذي شاركنا في تنفيذه مع مؤسسة مسك الخيرية</p>
                                <div className="embed-responsive embed-responsive-16by9 mb-4" data-aos="fade-up" data-aos-delay="800">
                                <iframe width="420" height="345" src="https://www.youtube.com/embed/tzgD12ywDHc"></iframe>
                                </div>
                                <div className="col-md-4 mx-auto" data-aos="fade-in" data-aos-delay="800">
                                <a className="text-white" href="mailto:Services@kaminsa.com" target="_blank">
                                <span className="btn btn-light btn-lg btn-block mb-n4">استفسر عن خدمات الشركات</span>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="my-5 py-5"></div>
            </div>
        </div >
    )
}
