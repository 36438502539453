import React from "react";
import Card from "../Card";

const Articles = ({ articles }) => {


  return (
    <div className="">
      <div className="" data-uk-grid>
        <div className="row">
          {articles.map((article, i) => {
            return (
            <div className="col-md-3 mb-3">
              <Card article={article} key={`article__${article.id}`} />
            </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Articles;
