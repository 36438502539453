import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import styles from "./Stories.module.scss";

const clamp = (value, clampAt = 30) => {
  if (value > 0) {
    return value > clampAt ? clampAt : value;
  } else {
    return value < -clampAt ? -clampAt : value;
  }
};

export default function Slider2(props) {
  const stories = [
    {
      image: "/images/stories/users/asma.JPG",
      name: "م.أسماء الأندجاني",
      description: " أخصائي منتج أول في شركة ثقة ",
      story:
        "الخدمة المقدمة من قبل فريق كامن خدمة متكاملة بحيث يخرج العميل منها بالمستند المرجو مع الكثير من النصائح والملاحظات التي تفيده في المستقبل لإبقاء مستنده محدث دائمًا. ناهيك عن احترافية الفريق في التعامل والتواصل ودقة المواعيد وسلاسة ووضوح الخطوات شكرًا فريق كامن",
      logo: "/images/stories/logos/logo1.png",
      title: null,
    },
    {
      image: "/images/stories/users/dalal.png",
      name: "دلال الشريف",
      description: "محاضر بجامعة الأمير سلطان",
      story:
        "أروع خدمة لصياغة السيرة الذاتية. مهما كانت حجم إنجازاتك فاطمة تحسسك بقيمتها. و تساعدك تصيغها بأفضل طريقة لجذب أصحاب الأعمال. في اقل من أسبوع خرجت بسيرة ذاتية رائعة من ناحية المحتوى و الشكل.",
      logo: "",
      title: null,
    },
    {
      image: "/images/stories/users/mohammed.jpg",
      name: "محمود باكوبين",
      description: "المدير التنفيذي لحسابات المنطقة الغربية",
      story: "الجهة الاستشارية الأولى لخدمات كتابة السيرة الذاتية الاحترافية",
      logo: "",
      title: "شركة جونسون اند جونسون",
    },
    {
      image: "/images/stories/users/DefaultAvatar.png",
      name: "عبدالله صلاح",
      description: "رجل أعمال ومستثمر في عدة مشاريع",
      story:
        "اول مرة اعمل سيرة ذاتية بطريقة احترافية واستفدت شخصياً من هذه التجربة و كيفية صناعة السيرة الذاتية بطريق ابداعية متقنه فريق متمكن و يساعدك في اخراج المعلومات بطريقة علمية جميلة كل التوفيق لكم و دمتم بود👍",
      logo: "",
      title: null,
    },
    {
      image: "/images/stories/users/DefaultAvatar.png",
      name: "الهنوف العبيشي",
      description: "مخترعة سعودية ومؤسسة مبادرة الخبير",
      story:
        "الاختلاف بين سيرتي الذاتية القديمة والجديدة كبير جداً، مع السيرة الذاتية الجديدة صارت تأتيني الردود على طلباتي للتدريب اسرع ✔️",
      logo: "",
      title: null,
    },
    {
      image: "/images/stories/users/DefaultAvatar.png",
      name: "ابتسام عبد الشكور",
      description: "مدربة حياة / Life Coach",
      story:
        "تجربتي معكم أضافت لي الكثير واهتمامكم بكل التفاصيل ساهم بالارتقاء بما هو أفضل لحياتي المهنية ،وإظهار نقاط قوتي ورسالتي من خلال السيرة الذاتية بأفضل صورة ممكنة بالفعل فاق توقعاتي ،أشكركم على تفانيكم ، وأتمنى لفريق كامن المزيد من التطور والنجاح.",
      logo: "",
      title: null,
    },
    {
      image: "/images/stories/users/DefaultAvatar.png",
      name: "إلهام سعود",
      description: "استشاري طب وجراحة عيون الاطفال",
      story:
        "متمكنين جدا في كتابه السيرة الذاتية بطريقه احترافيه ، تعاملهم وتعاونهم اكثر من رائع ، افضل بكثير من موقع امريكي تعاملت معه سابقا لكتابه السيرة الذاتية..ملتزمين جدا في الوقت ويخلصون الشغل كما الاتفاق بالضبط ...شكرا لكم جميعاً",
      logo: "",
      title: " مستشفى الملك فيصل التخصصي ومركز الأبحاث",
    },
    {
      image: "/images/stories/users/DefaultAvatar.png",
      name: "معزة فرج الله",
      description: "مشرف برامج المسؤولية الاجتماعية",
      story:
        "Working with Kamin was fantastic; they go the extra mile and give you solid advice to help point your career in the right direction. I can`t wait to work with them again. I highly recommend working with them to further your career.",
      logo: "",
      title: "شركة البيك للأنظمة الغذائية",
    },
    {
      image: "/images/stories/users/DefaultAvatar.png",
      name: "شهد مناع",
      description: "مستشار التطوير التنظيمي",
      story:
        "I can`t thank Kamin team enough! very proud to see such a quality and professional service in the Kingdom! I feel more confident applying with the new Resume, All the best Kamin Team!",
      logo: "",
      title: "جامعة الملك عبدالله للعلوم والتكنولوجيا",
    },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <div
          className={styles.carouselBackgroundRight}
          onClick={onClick}
          style={{ height: "100%", display: "flex", cursor: "pointer" }}
        >
          <botton
            style={{
              margin: "auto",
              color: "white",
              fontSize: "2.5rem",
              maxWidth: "2rem",
            }}
            disabled={isEdge}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-chevron-right"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </botton>
        </div>
      ) : (
        <div
          className={styles.carouselBackgroundLeft}
          onClick={onClick}
          style={{ height: "100%", display: "flex", cursor: "pointer" }}
        >
          <botton
            style={{
              margin: "auto",
              color: "white",
              fontSize: "2.5rem",
              maxWidth: "2rem",
            }}
            disabled={isEdge}
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-chevron-left"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </botton>
        </div>
      );
    return  pointer ;
  };

  return (
    <div className="w-100 carousel-sides">
      <Carousel
        itemsToShow={1}
        isRTL={true}
        enableMouseSwipe={true}
        pagination={false}
        disableArrowsOnEnd={false}
        className={styles.test}
        renderArrow={myArrow}
      >
        {stories.map((item, i) => (
          <div key={i} className="slider2-card h-100 w-100 mx-1">
            <div className={styles.carousel}>
              {/* <div className="media flex-column flex-md-row h-100 p-4 align-items-center">
                <div className={styles.carouselImage}> 
                {item.image && <img style={{ width: 80, height: 80 }} src={item.image} className="mx-3" alt="..." />}
                </div>
                <div>
                  <h5 className="mt-2 font-weight-bold text-white">{item.name}</h5>
                  <h6 className="mt-0 mb-3 font-weight-bold text-white">{item.description}</h6>
                  {item.story}
                </div>
              </div> */}
              <div className={styles.carousel__imageWrapper}>
                {item.image && (
                  <img
                    className={styles.carousel__image}
                    src={item.image}
                    alt="..."
                  />
                )}
              </div>

              <div className={styles.textWrapper}>
                <h5 className={styles.textWrapper__name}>{item.name}</h5>
                <h6 className={styles.textWrapper__desc}>{item.description}</h6>
                <h6 className={styles.textWrapper__title}>{item.title}</h6>
                <p className={styles.textWrapper__story}> {item.story} </p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
