import React, { useEffect, Component } from 'react'
import { DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'
import { animated, useSpring,config } from "react-spring";
// import { useScroll, useGesture } from "react-use-gesture";
import {Link} from 'react-router-dom';

export default class LandingCarousel extends Component {

    render() {
        return (
            <div dir="ltr" className={"jumbotron jumbotron-fluid landing-jumbotron mb-0 parallax " + this.props.bakground} style={{ minHeight: "100vh", paddingTop: 0, overflow: 'hidden', boxShadow: 'rgb(10, 13, 15) -8px -10px 13px 5px inset' }}>
                {/* <Parallax ref={ref => (this.parallax = ref)} pages={1.18} className="parallax" config={config.gentle}>
                    <ParallaxLayer
                        offset={0}
                        speed={-0.005}
                        factor={1.2}
                        onClick={() => this.parallax.scrollTo(1)}
                        style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', left: '0' }}>
                        <img src={'/images/paralax/BG4.png'} style={{ minWidth: '100%' }} />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0.0}
                        speed={-0.5}
                        factor={1.15}
                        onHover={() => this.parallax.scrollTo(1)}
                        style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', left: '0' }}>
                        <img src={'/images/paralax/BG2.png'} style={{ minWidth: '100%' }} />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0}
                        speed={0.5}
                        factor={1.2}
                        style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', left: '0' }}>
                        <img src={'/images/paralax/BG0.png'} style={{ minWidth: '100%' }} />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0.0}
                        speed={-0.5}
                        factor={1.15}
                        style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', left: '0' }}>
                        <img src={'/images/paralax/BG1.png'} style={{ minWidth: '100%' }} />
                    </ParallaxLayer>


                    <ParallaxLayer offset={1} style={{ backgroundColor: '#0a0a0a' }} />


                </Parallax> */}
                <div className="container py-5">
                    <div className="row mt-5">
                        <div className=" justify-content-center d-flex flex-column align-items-center mt-5">
                            <h1 className="display-5 pt-5 mt-5 text-center font-weight-bold text-white mb-4">{this.props.title}</h1>
                            <p className="lead text-center w-100 mb-5 px-5 py-1">{this.props.subtitle}</p>
                                <Link to="/services" spy={true} smooth={true} duration={1500} className="w-100">
                                    <button  className="btn btn-outline-primary btn-lg btn-block  mx-auto text-center landing-carousel-button">إبدأ الرحلة</button>
                                </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
