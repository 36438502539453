import React from 'react'

export default function Feature(props) {
    return (
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="card border-0 align-items-center align-items-md-start">
            <img src={"/images/"+props.image+".svg"} style={{ width: 75, height: 75 }} className="card-img-top pb-2 text-center text-md-right" alt="..." />
            <div className="card-body px-0" style={{padding: '0 0 1.25rem'}}>
              {props.title && <h5 className="card-title font-weight-bold text-center text-md-right">{props.title}</h5>}
              <p className="card-title  text-center text-md-right">{props.description}</p>
            </div>
          </div>
        </div>
    )
}
