import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import App from "./containers/App";
import ScrollToTop from './scrollToTop'
import client from "./utils/apolloClient";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";
import "bootstrap/dist/js/bootstrap"
import './index.scss';
import "./index.css";

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
      <ScrollToTop />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);
