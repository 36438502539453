import React from 'react'
import Helmet from 'react-helmet'
export default function Privacy() {
    return (
        <div className="pt-5">
            <Helmet>
                <meta charSet="utf-8" />
                <title>كامن</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <div className="container pt-5 mt-5" >
                <div className="row pt-5">
                    <div className="col-12">
                        <h3 className="text-white mb-3">الشروط والأحكام</h3>
                    </div>
                    <div className="col-12 mb-5" style={{ background: "rgba(216,216,216,.1" }}>
                        <div className="p-4">
                        <h3 className="text-white font-weight-bold my-4">أ. التوقعات</h3>
                        <p className="mb-1">1.  يقر العميل بصحة البيانات التي قام أو سيقوم بمشاركتها مع فريق كامن وبأن أي تضليل أو تزوير في البيانات سيتسبب في الحرمان من الخدمة.</p><p className="mb-1">
                        2. يوافق العميل على العمل وفق منهجية ومعايير كامن في كتابة السيرة الذاتية، والتي سيتم توضيحها خلال المكالمة الاستشارية. وفي حال لم يوافق العميل على أي من هذه المعايير، يحق له استرجاع المبلغ كاملاً قبل بدء العمل على السيرة الذاتية.</p><p className="mb-1">
                        3. يوافق العميل على استلام أول مسودة من السيرة الذاتية خلال (4) أربعة أيام عمل من تاريخ المكالمة الاستشارية.</p><p className="mb-1">
                        4. يوافق العميل على مراجعة السيرة الذاتية للتأكد من دقتها (بما في ذلك الأخطاء والمحتوى المفقود أو غير الكامل) والرد عبر البريد الإلكتروني مع أي تغييرات أو تصحيحات (أو الموافقة على الانتهاء) خلال (7) أيام من استلام المسودة. في حال تأخر الرد، سيتم افتراض أن السيرة الذاتية مقبولة، وسيتم إنهاء المشروع. أي عمل يطلبه العميل بعد ذلك سيتم اعتباره كخدمة جديدة.</p><p className="mb-1">
                        5. فريق كامن غير مسؤول عن أي تأخير عن الموعد النهائي في حال تأخَّر العميل في تقديم المعلومات أو لم يوافق على العمل في الوقت المناسب في أي مرحلة من مراحل العملية.</p><p className="mb-1">
                        6. يقدم فريق كامن نسخًا نهائية من السيرة الذاتية بصيغتي ( Word  و PDF )</p><p className="mb-1">
7. يدرك العميل أن أيام عمل فريق كامن هي الأحد إلى الخميس باستثناء أيام العطل الوطنية.</p>
                        <h3 className="text-white font-weight-bold my-4">ب. التنقيحات</h3>
                        <p className="mb-1">1. يقر العميل بأنه لا يحق له أكثر من (2) جولتين من التعديلات المجانية.</p><p className="mb-1">
                        2. في حال وجود أي تعديلات، يجب أن تظل ضمن نطاق العمل المتفق عليه، وفي حال طلب العميل تغييرات جذرية مثل تغيير المسار الوظيفي وتوجه السيرة الذاتية، سيتم تطبيق رسوم إضافية.</p><p className="mb-1">
3. أي تعديلات إضافية بعد الـ (2) جولتين ستخضع لرسوم إضافية بمعدل (100 ريال/ساعة عمل).</p>
                        <h3 className="text-white font-weight-bold my-4">ج. الدفع</h3>
                        <p className="mb-1">1. الدفع مسبق لكامل المبلغ عن طريق حوالة بنكية.</p><p className="mb-1">
2. الرسوم غير قابلة للاسترداد بعد بدء العمل.</p>
                    </div>
                    </div>
                    <div className="col-12">
                        <h3 className="text-white mb-2">سياسة الخصوصية:</h3>
                    </div>
                    <div className="col-12 mb-5" style={{ background: "rgba(216,216,216,.1" }}>
                    <p className="py-4 px-4">تلتزم كامن بالحفاظ على السرية التامة لمعلومات العميل ولا يجوز لها، دون موافقة خطية مسبقة، استخدام أو الكشف لأي جهة خارجية، بشكل مباشر أو غير مباشر، عن أي من معلومات العميل.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
