import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import Stories from "../../shared/Stories";
import LandingCarousel from "../../components/LandingCarousel";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import Feature from "../../shared/Feature";
import { Helmet } from "react-helmet";
import AOS from 'aos';
import { Waypoint } from 'react-waypoint';




const HomePage = () => {
  const {state} = useLocation();
  const steps = useRef();
  const items = [
    "/images/partners/SAUDI ARAMCO.png",
    "/images/partners/GSA.png",
    "/images/partners/EY.png",
    "/images/partners/KPMG.png",
    "/images/partners/MISK.png",
    "/images/partners/NCB.png",
    "/images/partners/PIF.png",
    "/images/partners/PWC.png",
    "/images/partners/RIYAD BANK.png",
    "/images/partners/SABIC.png",
  ]
  var partners = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 0,
    cssEase: "linear",
    rtl: true
  }

  useEffect(() => {
    AOS.init({
      duration: 1500
    })
    if(state){
      steps.current.scrollIntoView({behaviour: "smooth"});
    }
  })

  return (
    <div>
      <Helmet>

        <meta charSet="utf-8" />
        <title>الرئيسية</title>
        <link rel="canonical" href="" />
      </Helmet>
      <LandingCarousel title="قصتك الكامنة تتجلى" subtitle="أسسنا كامن لنحدث تغييرًا إيجابيًا في حياتك. غايتنا هي مساعدتك على إبراز قدراتك الكامنة وإنجازاتك المهنية عبر خدماتنا الكتابية والاستشارية" background="ocean2-background"/>

      <div className="uk-section pt-0" >  
        <div className="" name="section1">
          <main role="main" className="position-relative mb-5" style={{ overflow: "hidden" }}>
            <div className="jumbotron-fluid mb-5  black-gradient">
              <div className="container mt-0 pt-5 mb-5">
                <h1 className="text-white font-weight-bold pt-5 mb-4">دعنا نروي قصتك المهنية</h1>
                <p className="lead" style={{ fontSize: "18px" }}>لدى كل منا قدرات خاصة وقصة فريدة بحاجة لمن يرويها. دعنا نأخذك في رحلة عملية برفقة أحد خبراءنا المهنيين؛<br /> تبدأ بالتعرف عليك وعلى احتياجاتك وتنتهي باستلامك للخدمات التالية:</p>

              </div>
            </div>
            <div className="container mb-5 pb-4">
              <div className="row pt-5 pb-3 px-3 mb-5 px-5" style={{ background: "rgba(216,216,216,.1" }}>
                <Feature title="كتابة سيرة ذاتية" image="cv" />
                <Feature title='كتابة نبذة شخصية' image="resume" />
                <Feature title='كتابة Cover Letter' image="profile" />
                <Feature title='تطوير LinkedIn' image="linkedin" />
                <div class="col-md-3 mx-auto">
                  <Link to="/services">
                  <button className="btn btn-lg btn-light btn-light-special btn-block py-3 mb-n5">تصفح الخدمات</button>
                  </Link>
                </div>

              </div>
            </div>

            <div className="container-fluid my-5">
              <div className="container px-5 mb-5" style={{ background: "rgba(222,222,222,.1" }} data-aos="fade-up">
                <div className="row py-5 ">
                  <div className="col-12">
                    <h1 className="display-4 text-white mb-4">يستقل رحلاتنا كلا من</h1>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12" data-aos="fade-in">
                    <div className="card border-0">
                      <div className="card-img-top custom-card-img-top">
                        <img src="/images/leader.svg" style={{ width: 90, height: 100 }} className="mr-3" alt="..." />
                      </div>

                      <div className="card-body px-0 mt-3">
                        <h4 className="card-title font-weight-bold text-white mb-4">الطموحين</h4>
                        <p className="card-subtitle text-white">الباحثين عن عمل أو تحدي أو شغف جديد</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12" data-aos="fade-in" data-aos-delay="300">
                    <div className="card border-0">
                      <div className="card-img-top custom-card-img-top">
                        <img src="/images/leadership.svg" style={{ width: 100, height: 100 }} className="card-img-top" alt="..." />
                      </div>
                      <div className="card-body px-0 mt-3">
                        <h4 className="card-title font-weight-bold text-white mb-4">المتميزين</h4>
                        <p className="card-subtitle text-white">الراغبين في تطوير صورتهم المهنية مثل المدراء التنفيذيين و رواد الأعمال </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12" data-aos="fade-in" data-aos-delay="600">
                    <div className="card border-0">
                      <div className="card-img-top custom-card-img-top pr-2">
                        <img src="/images/company.svg" style={{ width: 90, height: 100 }} className="card-img-top" alt="..." />
                      </div>
                      <div className="card-body px-0 mt-3">
                        <h4 className="card-title font-weight-bold text-white mb-4">الشركات</h4>
                        <p className="card-subtitle text-white">التي تطمح لأن تبرز قوة موظفيها وتطوير نبذتهم الشخصية وسيرتهم الذاتية</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid my-5">
              <div className="container p-5" style={{ background: "rgba(222,222,222,.1" }} data-aos="fade-up">
                <div className="row justify-content-between align-items-center">

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h1 className="display-4 text-white">خدمنا حتى الآن</h1>
                    <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                      {({ isVisible }) => (
                        <h1 className="display-3 font-weight-bold text-white">{isVisible ? <CountUp style={{ color: '#fff' }} end={450} duration={6} /> : 0}+</h1>
                      )}

                    </VisibilitySensor>

                    <h2 className="text-white">عميل من مختلف دول العالم</h2>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                  <Link to="/services"><button className="btn btn-lg btn-light btn-block d-flex justify-content-between align-items-center py-3"><span>إبدأ الرحلة </span><img src="images/button-arrow-left.svg" width="20" /></button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5">
              <div className="container">
                <h1 className="text-white font-weight-bold">تلقى عملاؤنا مقابلات عمل من شركات أبرزها</h1>
                <Slider {...partners} className="partners">
                  {items.map(item => {
                    return <img className="m-2 carousel-card" width={"250"} src={item} alt="partner" />
                  })}
                </Slider>
              </div>


            </div>
            <div className="container">
              <h1 className="text-white font-weight-bold mb-4">ألهمونا وألهمناهم</h1>
              <Stories />
            </div>


          </main>
        </div>
        <div className="container" data-aos="fade-left" ref={steps}>
          <div className="row mb-3" style={{ background: "url(/images/arrow-large-left.svg) no-repeat left bottom" }}>
            <div className="col-12 mb-4">
              <h1 className="text-white font-weight-bold">خطوات رحلتك</h1>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="card border-0 align-items-center align-items-md-start">
                <img src="/images/choose.svg" style={{ width: 100, height: 100 }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">اختر الخدمة</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="card border-0 align-items-center align-items-md-start">
                <img src="/images/pay.svg" style={{ width: 100, height: 100 }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">أتمّ عملية الدفع</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="card border-0 align-items-center align-items-md-start">
                <img src="/images/review.svg" style={{ width: 100, height: 100 }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">تحدث مع الخبير المهني</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="card border-0 align-items-center align-items-md-start">
                <img src="/images/medal.svg" style={{ width: 100, height: 100 }} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title">استلم المنتج</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5" data-aos="fade-up">
          <div className="container" style={{ background: "linear-gradient(90deg, #C7AB86 0%, #F6DAA5 42.19%, #FFE4B2 52.55%, #F6DAA5 61.3%, #C7AB86 93.67%, #F6DAA5 100%)", boxShadow: "0 2px 100px 0 rgba(0,0,0,0.15)" }}>
            <div className="row justify-content-between align-items-center py-3">
              <div className="col-md-7">
                <h2 className="text-dark">تواصل معنا لتبدأ رحلتك</h2>
              </div>
              <div className="col-md-3">
              <Link className="text-white" to="/services"> <button className="btn btn-lg btn-light btn-block d-flex justify-content-between align-items-center py-3"><span>إبدأ الرحلة</span><img src="images/button-arrow-left.svg" width="20" /></button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default HomePage;
