import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from "react-router"
import { Helmet } from "react-helmet";
import Accordion from '../../components/Accordion/Accordion';


export default function FAQ() {
    let history = useHistory()
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    function submitForm(event) {
        event.preventDefault()
        var object = {
            name: name,
            email: email,
            message: message
        };
        axios.post('https://kamen-cms.herokuapp.com/contact-requests', object)
            .then(function (response) {
                //handle success
                setSubmitted(true);
                history.push("/success")


            })
            .catch(function (response) {
                //handle error
            });
    }

    return (
        <div className="my-5 py-5">
            <Helmet>
                <meta charSet="utf-8" />
                <title>أسئلة متكررة</title>
            </Helmet>
            <div className="container mt-5">
                <div className="row">
                    <h2 className="text-white mb-4 mx-3 font-weight-bold">نجيب هنا عن جميع تساؤلاتك</h2>
                    <div className="col-12 py-4 px-0" style={{ background: "rgba(216,216,216,.05" }}>
                        <Accordion
                            title="ماهي المدة الزمنية المستغرقة لإتمام الخدمة؟"
                            content="4  أيام عمل لاستلام المسودة الأولى، وباقي المدة تعتمد على سرعتك في التجاوب"
                        />
                        <Accordion
                            title="ماهي خطوات العمل؟"
                            content='services'
                        />
                        <Accordion
                            title="هل من الممكن استلام العمل خلال 24 ساعة؟"
                            content="نعم، وينتج عنه زيادة في سعر الخدمة بنسبة 100%"
                        />
                        <Accordion
                            title="كيف أتأكد من أن المخرج النهائي بأفضل جودة؟ وماذا يحدث إن لم أكن راضٍ عن المخرج النهائي؟"
                            content="لضمان رضاك وقناعتك، نشرح لك منذ المكالمة الأولى منهجيتنا في العمل والمتوقع من المخرج، كما تسبق النسخة النهائية جولتان من التعديلات التي تتم بموافقتك."
                        />
                        <Accordion
                            title="ما الهدف من المكالمة الاستشارية؟"
                            content="الهدف منها هو أن تعرفنا عليك وعلى أهدافك والطريق الذي تسمو إليه مهنيا، بالتالي نستطيع فهم خبراتك، مواهبك، وإنجازاتك. كما يتم مناقشة منهجياتنا في العمل والخطوات المتوقعة لإتمام المهمة. "
                        />
                        <Accordion
                            title="كيف سيكون تصميم نموذج السيرة؟"
                            content="نشاركك مجموعة مختلفة من القوالب لتختار منها الأنسب لك"
                        />
                        <Accordion
                            title=" إن أردت القيام بأي تعديلات أو معلومات إضافية بعد استلامي للنسخة النهائية سواء فورا بعد استلام العمل أو بعد عدة أشهر من استلام النسخة النهائية ماذا أفعل؟ "
                            content="بالتأكيد سنقوم بها ويكون سعر الخدمة 100 ريال في الساعة"
                        />
                        <Accordion
                            title="هل من الممكن رؤية عينة من أعمالكم السابقة؟"
                            content="بالتأكيد، ويكون ذلك عن طريق طلبك من الخبير المعين لك"
                        />
                        <Accordion
                            title="بالنسبة لمعلوماتي وخصوصيتي، هل يتم مشاركتها مع أي جهة أو أشخاص خارج شركة كامن دون إذني؟"
                            content="لا يتم مشاركة معلوماتك ولا سيرتك الذاتية إلا بعد أخذ موافقتك"
                        />
                        <Accordion
                            title="هل من الممكن ترشيحي لشركات أرغب في العمل بها إن كانت من ضمن علاقاتكم العامة؟"
                            content="نعم، إن كانت الفرصة ملائمة لك. ويتم ذلك بعد أخذ موافقتك"
                        />
                        <Accordion
                            title="هل كامن شركة توظيف؟"
                            content="لا"
                        />
                        <Accordion
                            title="بالنسبة للدفع، هل يمكنني استرجاع المبلغ المدفوع؟"
                            content="نعم، إذا تم إلغاء الخدمة قبل المكالمة الاستشارية"
                        />
                        <Accordion
                            title="هل يمكنني الدفع بعد استلام الخدمة؟"
                            content="لا، يكون الدفع قبل المكالمة الاستشارية"
                        />
                        <Accordion
                            title="ماهي طريقة الدفع المتاحة؟"
                            content="الحوالة البنكية"
                        />
                        <Accordion
                            title="هل هناك أي تخفيضات أو عروض موسمية؟"
                            content="يوجد تخفيض عند طلب أكثر من خدمة لنفس الشخص"
                        />
                        <Accordion
                            title="ما هي المنهجية المتخذة وراء تحديد أسعار الخدمات؟"
                            content="أسعار كامن حُددت بناءًا على عدد الساعات المتوقعة للعمل على الخدمة ومستوى الخبرة المطلوب من منفذ الخدمة، كما أن منهجية عملنا مستوحاة من منافسينا من مختلف الدول مثل الولايات المتحدة الأمريكية والمملكة المتحدة."
                        />
                    </div>
                </div>

            </div>
            <div className="row mb-4" />
            <div className="container mb-5" style={{ background: "rgba(216,216,216,.1" }}>
                <form onSubmit={submitForm.bind(this)}>
                    <div className="row justify-content-center pt-5">

                        <div className="col-md-12 mb-5">
                            <h2 className="text-center text-white font-weight-bold">إن لم تجد إجابة لسؤالك ،تواصل معنا و سنجيبك في أقرب فرصة</h2>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label for="name">الاسم</label>
                            <input type="text" className="form-control shadow-none bg-light rounded-0 py-4" placeholder="اترك اسمك هنا " onChange={e => { setName(e.target.value) }} id="name" aria-describedby="name" />
                        </div>

                        <div className="col-md-4 mb-3">
                            <label for="email">البريد الالكتروني </label>
                            <input type="text" className="form-control shadow-none bg-light rounded-0 py-4" placeholder="اترك بريدك هنا " onChange={e => { setEmail(e.target.value) }} id="email" aria-describedby="emailHelp" />
                        </div>
                        <div className="col-md-8 mb-5">
                            <label for="message">التعليق</label>
                            <textarea type="text" className="form-control input-group-text shadow-none bg-light rounded-0 py-4 text-right" placeholder="اترك تعليقك هنا" onChange={e => { setMessage(e.target.value) }} id="message" />
                        </div>

                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3 mb-n4">
                            <button type="submit" className="btn btn-light btn-light-special btn-lg btn-block py-3">أرسل </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}
