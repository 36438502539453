import React from 'react'
import { Button } from 'react-scroll'

export default function Services(props) {
    return (
        <div className="col-md-3 col-sm-6 my-5 " style={{maxHeight:'220px'}}>
        <div className="card mb-5 rounded-0 h-100 padding-animation" style={{background:'rgba(255,255,255,0.05)'}}>
            <img src={"images/" + props.image + ".svg"} style={{ Width: "95px",maxHeight:'95px' }} className="card-img-top mx-auto mt-n5" alt="" />
            <div className="card-body text-center pb-0">
                <h4 className="card-title text-center text-white font-weight-bold">{props.title}</h4>
                <p className="card-text text-center text-white">{props.description}</p>
            </div>
            <button className="btn btn-light btn-light-special2 btn-lg mb-n4 mx-3 px-1  " style={{fontSize:'14px', cursor: 'auto'}}>{props.price}</button>
        </div>
        </div>
    )
}
