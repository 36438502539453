import React, { useState } from 'react'
import LandingCarousel from "../../components/LandingCarousel";
import Stories from "../../shared/Stories";
import range from 'lodash/range';
import styled from 'styled-components';
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import AOS from 'aos';
import { Link } from 'react-router-dom';



export default class StoriesPage extends React.Component {
  state = {
    activeItemIndex: 0,
  };


  componentDidMount() {
    AOS.init({
      duration: 1500
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  onChange = value => this.setState({ activeItemIndex: value });
  items = [
    "/images/partners/kacst.png",
    "/images/partners/saudi-seasons.png",
    "/images/partners/monshaat.png",
    "/images/partners/bonyan.png",
    "/images/partners/stcs.png",
    "/images/partners/cgc.png",

  ]
  render() {
    const items = [
      "/images/partners/SAUDI ARAMCO.png",
      "/images/partners/GSA.png",
      "/images/partners/EY.png",
      "/images/partners/KPMG.png",
      "/images/partners/MISK.png",
      "/images/partners/NCB.png",
      "/images/partners/PIF.png",
      "/images/partners/PWC.png",
      "/images/partners/RIYAD BANK.png",
      "/images/partners/SABIC.png",
    ]
    var cityCarousel = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 6000,
      autoplaySpeed: 0,
      cssEase: "linear",
      rtl: true
    }
    var leftCarousel = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 6000,
      autoplaySpeed: 0,
      cssEase: "linear"
    };
    var rightCarousel = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 6000,
      autoplaySpeed: 0,
      cssEase: "linear",
      rtl: true
    }
    var partners = {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll:1 ,
      autoplay: true,
      speed: 6000,
      autoplaySpeed: 0,
      cssEase: "linear",
      rtl: true
    }
    return (
      <div>
        <div style={{ height: '15vh' }}></div>
        <Helmet>
          <meta charSet="utf-8" />
          <title> قصص عملائنا</title>

        </Helmet>
        <div className="container">
          <h1 className="text-white font-weight-bold mt-3">أماكن تواجد عملاءنا</h1>

        </div>
        <div className="container container-carousel text-center">
          <div className="py-2 slider-shadow" style={{ backgroundColor: 'rgba(255,255,255,0.1' }}>
            <Slider {...cityCarousel}>
              <div><p className="text-white">جدة</p></div>
              <div><p className="text-white">مكة</p></div>
              <div><p className="text-white">الرياض</p></div>
              <div><p className="text-white">المدينة المنورة</p></div>
              <div><p className="text-white">الطائف</p></div>
              <div><p className="text-white">ينبع</p></div>
              <div><p className="text-white">الدمام</p></div>
              <div><p className="text-white">دبلن</p></div>
              <div><p className="text-white">دبي</p></div>
              <div><p className="text-white">تونس</p></div>
              <div><p className="text-white">واشنطن</p></div>
              <div><p className="text-white">كاليفورنيا</p></div>
            </Slider>
          </div>
          <div className={"pt-2"}>
            <img src="/images/clientsLocations.svg"></img>
          </div>
        </div>
        <div className="container mt-5 mb-1">
          <h1 className="text-white font-weight-bold">مجالات عملائنا</h1>
        </div>
        <div className="container-carousel container mt-2">
          <div className="mb-5 pb-5 ">
            <Slider className="mb-3 slider-shadow" {...leftCarousel}>
              <div><p className="text-primary">الهندسة</p></div>
              <div><p className="text-primary">الطب</p></div>
              <div><p className="text-primary">القانون</p></div>
              <div><p className="text-primary">العلوم</p></div>
              <div><p className="text-primary">الشريعة</p></div>
              <div><p className="text-primary">العقار</p></div>
              <div><p className="text-primary">الفنون</p></div>


            </Slider>
            <Slider className="slider-shadow" {...rightCarousel}>
              <div><p className="text-primary">إدارة الأعمال</p></div>
              <div><p className="text-primary">مالية ومحاسبة</p></div>
              <div><p className="text-primary">علوم الحاسب</p></div>
              <div><p className="text-primary">صيدلة</p></div>
              <div><p className="text-primary">التسويق</p></div>
              <div><p className="text-primary">موارد بشرية</p></div>
              <div><p className="text-primary">الإقتصاد</p></div>
            </Slider>
          </div>
        </div>

        <div className="container" >
          <div className="row mb-5 px-5 pt-5 justify-content-between" style={{ background: "rgba(216,216,216,.1" }}>
            <h1 className="col-md-12 text-white mb-4 font-weight-bold" data-aos="fade-in" data-aos-delay="200">مناصب عملائنا</h1>
            <div className="col-md-3 d-flex flex-column justify-content-between align-items-center py-4" data-aos="fade-in" data-aos-delay="200">
              <img src="/images/chart20percent.svg" />
              <h2 className="mt-3 text-white text-center font-weight-bold">23%</h2>
              <p>المبتدئين</p>
            </div>
            <div className="col-md-3 d-flex flex-column justify-content-between align-items-center py-4" data-aos="fade-in" data-aos-delay="500">
              <img src="/images/chart18percent.svg" />
              <h2 className="mt-3 text-white text-center font-weight-bold">18%</h2>
              <p>المحترفين</p>
            </div>
            <div className="col-md-3 d-flex flex-column justify-content-between align-items-center py-4" data-aos="fade-in" data-aos-delay="800">
              <img src="/images/chart20percent.svg" />
              <h2 className="mt-3 text-white text-center font-weight-bold">20%</h2>
              <p>المتقدمين</p>
            </div>
            <div className="col-md-3 d-flex flex-column justify-content-between align-items-center py-4" data-aos="fade-in" data-aos-delay="1100">
              <img src="/images/chart39percent.svg" />
              <h2 className="mt-3 text-white text-center font-weight-bold">39%</h2>
              <p>التنفيذيين</p>
            </div>
          </div>
        </div>
        <div className="container mb-0">
          <h1 className="text-white font-weight-bold mb-3">تلقى عملاؤنا مقابلات عمل من شركات أبرزها</h1>
          <Slider {...partners} className="partners">
          {items.map(item => {
            return <img className="m-2 carousel-card" src={item} alt="partner" />
          })}
        </Slider>
        </div>

        <div className="container mt-5 mb-5">
          <h1 className="text-white font-weight-bold">ألهمونا وألهمناهم</h1>
          <Stories />
        </div>

        <div className="container-fluid">
          <div className="container" style={{ background: "linear-gradient(90deg, #C7AB86 0%, #F6DAA5 42.19%, #FFE4B2 52.55%, #F6DAA5 61.3%, #C7AB86 93.67%, #F6DAA5 100%)", boxShadow: "0 2px 100px 0 rgba(0,0,0,0.15)" }}>
            <div className="row justify-content-between align-items-center py-3">
              <div className="col-md-7">
                <h2 className="text-dark">تواصل معنا لتبدأ رحلتك</h2>
              </div>
              <div className="col-md-3">
              <Link to='/services'> <button className="btn btn-lg btn-light btn-block d-flex justify-content-between align-items-center py-3"><span>إبدأ الرحلة </span><img src="images/button-arrow-left.svg" width="20" /></button></Link>

              </div>
            </div>


          </div>
        </div>
      </div>
    )
  }
}
