import React from 'react'
import { useTranslation, Trans } from "react-i18next";
import {Link} from 'react-router-dom'

export default function Footer() {
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const index = 11;

    return (
        <div>
        <div className="fluid-container pt-5 mb-0" style={{background:"#1F2B38"}}>
            <footer className="container pt-1 pt-md-3 pb-4 mb-0">
                <div className="row">
                    <div className="col-12 col-md-3 text-center">
                        <img src="/images/logo.svg" style={{width:"250px"}} alt="Logo"/>
                        {/* <h2 className="text-white">{t("Welcome to React")}</h2>
                        <button onClick={() => changeLanguage("ar")}>AR</button>
                        <button onClick={() => changeLanguage("en")}>EN</button>*/}
                        <p className="d-block my-4 text-white text-center">جميع الحقوق محفوظة 2020©</p> 
                    </div>
                    <div className="col-6 col-md-2 mb-4">
                        <ul className="list-unstyled text-small pr-0">

                            <li className="mb-2"><Link className="text-white" to="/services">خدماتنا</Link></li>
                            <li className="mb-2"><Link className="text-white" to="/about-us">من نحن</Link></li>
                            <li className="mb-2"><Link className="text-white" to="/success-stories">قصص عملائنا</Link></li>
                            <li><Link className="text-white" to="/privacy-policy">الشروط و الخصوصية</Link></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-2 mb-4">
                        <ul className="list-unstyled pr-4">
                            <li className="mb-2"><Link className="text-white" to="/faqs">أسئلة متكررة</Link></li>
                            {/* <li className="mb-2"><Link className="text-white" to="/articles">المدونة</Link></li> */}
                            <li className="mb-2"><a className="text-white" href="mailto:Services@kaminsa.com" target="_blank">تواصل معنا</a></li>
                            <li className="mb-2"><a className="text-white" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfpNuZwyduEuFPnL_edn_3WwJyh9TLPiNgoVCTKsx1JSezVWA/viewform">إنضم إلينا</a></li>

                        </ul>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="">
                            {/* <h6 className="text-white mb-0">اشترك معنا لتصلك كتاباتنا الجديدة</h6>
                            <div className="input-group my-3">
                                <input type="text" className="form-control border-0" placeholder="البريد الإلكتروني" aria-label="Example text with button addon" aria-describedby="button-addon1" style={{background:"rgba(255,255,255,0.05)",color:"#fff"}} />
                                <div className="input-group-prepend" style={{zIndex: 0}}>
                                    <button className="btn btn-primary" type="button" id="button-addon1">اشترك</button>
                                </div>
                            </div> */}
                            <a href="https://www.linkedin.com/company/kamin-%D9%83%D8%A7%D9%85%D9%86/" target="_blank" className="fab fa-linkedin fa-2x text-primary p-1"></a>
                            <a href="https://www.instagram.com/kamin_cv/" target="_blank" className="fab fa-instagram fa-2x text-primary p-1 mx-3"></a>
                            <a href="https://twitter.com/kamin_cv" target="_blank" className="fab fa-twitter fa-2x text-primary p-1"></a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </div>
    )
}
