import React, { useState, useRef } from "react";
import Chevron from "./Chevron";

import "./Accordion.css";
import { Link } from "react-router-dom";

export default function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("plus text-dark");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "plus text-dark" : "minus text-white"
    );
  }

  return (
    <div className="accordion__section">
      <button className={`accordion d-flex align-items-center ${setActive}`} onClick={toggleAccordion}>
      <i className={`fal fa-${setRotate} pl-3`} />
        <p className="accordion__title">{props.title}</p>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          // dangerouslySetInnerHTML={{ __html: props.content }}
        >
          {props.content === 'services' ? (
                      <Link style={{color: 'white'}} to={{pathname: '/', state: 'steps'}}>
                      إضغط هنا لإستعراض الخطوات
                      </Link>
          ): 
          props.content
          }

          </div>
      </div>
    </div>
  );
}